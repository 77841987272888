import React, {useState} from 'react';
import './NavBar.css'
import ChampionsNorthLogo from '../../assets/logos/champions-north.png'


export function NavBar () {
  return (
    <>
      <div className='nav'>
        <div className='home'>
          <a href="/">
            <img src={ChampionsNorthLogo} alt="Champions North Logo" />
          </a>
        </div>
        <div className="nav-items">
          <a href="/management-and-coaching">Coaching</a>
          <a href="/the-process">The Process</a>  
          <a href="/partnerships">Partnerships</a> 
          <a href='/highlighted-players'>Highlighted Players</a> 
          <a href="/register">Register</a>
        </div>
        <div className="mobile-nav">
          <button 
            class="nav-button" 
            type="button" data-bs-toggle="offcanvas" 
            data-bs-target="#offcanvasExample" 
            aria-controls="offcanvasExample"
          >
            <div className="nav-line"></div>
            <div className="nav-line"></div>
            <div className="nav-line"></div>
          </button>
        </div>
      </div>
      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
          <div className="mobile-close">
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
        </div>
        <div class="offcanvas-body">
        <div className='home'>
        </div>
          <div className="mobile-nav-section">
            <a href='/'>Home</a>
            <a href="/management-and-coaching">Coaching</a>
            <a href="/the-process">The Process</a>  
            <a href="/partnerships">Partnerships</a> 
            <a href='/highlighted-players'>Highlighted Players</a> 
            <a href="/register">Register</a>
          </div>
        </div>
      </div>
    </>
  )
};