import {Route} from 'react-router-dom';
import './App.css';
import {NavBar} from './components/NavBar/NavBar';
import {Footer} from './components/Footer/Footer';
import {LandingPage} from './pages/LandingPage/LandingPage';
import {AboutUs} from './pages/AboutUs/AboutUs';
import {Partnerships} from './pages/Partnerships/Partnerships';
import {OurProcess} from './pages/OurProcess/OurProcess';
import {Contact} from './pages/Contact/Contact';
import {HighlightedPlayers} from './pages/HighlightedPlayers/HighlightedPlayers';

function App() {
  const {pathname} = window.location;

  return (
    <div className="App">
      <NavBar />
      <Route path='/' exact component={LandingPage} />
      <Route path='/management-and-coaching' exact component={AboutUs} />
      <Route path='/the-process' exact component={OurProcess} />
      <Route path='/partnerships' exact component={Partnerships} />
      <Route path='/highlighted-players' exact component={HighlightedPlayers} />
      <Route path='/register' exact component={Contact} />
      {pathname !== '/register' && <Footer />}
    </div>
  );
}

export default App;
