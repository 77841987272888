import React from 'react';
import './Partnerships.css'

import GameDayLogo from '../../assets/logos/game-day-therapy-logo.png';
import VeriaLogo from '../../assets/logos/veria-logo.png';
import OdyseasLogo from '../../assets/logos/odyseas-moschatou-logo.png';

export function Partnerships () {

  const gamedayInfo = {
    heading: "Gameday Sports Therapy",
    paraOne: "In order to ensure our players are in good health and in top physical form, we have partnered with Dr. Milad Mohib of GAMEDAY SPORTS THERAPY. Dr. Mohib graduated from the University of Western Ontario with an Honours Degree in Kinesiology and obtained a Doctorate of Chiropractic at the Canadian Memorial Chiropractic College in 2014. He has also completed a Masters Degree in Human Health and Nutritional Sciences and is currently a Sports Resident at the Royal College of Chiropractic Sports Sciences. Physical assessments, baseline concussion testng, strength and agility, strength and conditioning, nutritional education and sports rehabilitation and therapy will all fall under the purview of GAMEDAY."
  };

  const gameDayServices = [
    {
      heading: "Our Health Mission",
      text: "Our team of practioners will address all of your gameday needs. We provide a comprehensive therapy experience that encompasses sports specific injury diagnosis, rehabilitation, conditioning, nutrition and mental toughness training. Our goal is to get you back to your game as quickly as possible"
    },
    {
      heading: "Experience and Professionalism",
      text: "With years of experience providing support for York Region’s competive teams, our practioners will provide you with a unique sports science experience catered to your level of performance. Our sports science team specializes in Long Term Athletic Development and utilizes the newest techniques and metrics available to improve performance."
    },
    {
      heading: "Empowerment and Education",
      text: "We help our clients learn skillsets and tools that will help them build and grow as athletes with every session. They are taught techniques and strategies to facilitate their success on Gameday and in their day-to-day lives."
    }
  ];

  return (
    <div className="partnerships-page">
      <div className="section app-section">
        <h2>{gamedayInfo.heading}</h2>
        <p>{gamedayInfo.paraOne}</p>
      </div>
      <div className="gameday-section app-full-width">
        <div className="gameday-logo-container">
          <img src={GameDayLogo} alt="" />
        </div>
        <div className="gameday-table app-section">
          {gameDayServices.map(({heading, text}) => {
            return (
              <div className="gameday-card">
                <h3 className="gameday-heading">{heading}</h3>
                <p className="gameday-text">{text}</p>
              </div>
            )
          })}
        </div>
      </div>
      <div className="associated-clubs section app-section">
        <div className="associated-clubs-text-container">
          <h2>Associated Clubs</h2>
          <p>Champions North has managed to secure sanctioned affiliations with both of these reputable football clubs.</p>
          <p><span className='team-highlight'>Veria FC</span> is a professional club based in Veria, Greece that plays in the Super League 2, the second tier of Greek football. Several of our players have already been there for training excursions. <span className='team-highlight'>Odysseas Mochato</span> is team playing in the Pan-Hellenic Women’s Football Championship. It is the highest professional women’s football league in Greece.</p>
        </div>
        <div className='associated-clubs-logo-container'>
          <div className='club-logo'>
            <img src={VeriaLogo} alt="veria logo" />
            <h3>Veria Football Club</h3>
          </div>
          <div className='club-logo'>
            <img src={OdyseasLogo} alt="odyseas logo" />
            <h3>Odysseas Moschato</h3>
          </div>
        </div>
      </div>
    </div>
  )
}