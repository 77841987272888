import React from 'react';
import './LandingPage.css'
import TeamPhoto from '../../assets/images/team-photo.jpg';
import ThreeSoccerFields from '../../assets/images/three-soccer-fields.jpg';
import SoccerBalls from '../../assets/images/soccer-balls.jpg';
import SoccerGoalie from '../../assets/images/soccer-goalie.jpg';

export function LandingPage () {
  return (
    <>
      <div className="hero-full-width">
          <div className="title-container">
            <h1 className='line-1'>Champions</h1>
            <h1 className='line-2'>Football</h1>
            <h1 className='line-3'>Academy</h1>
            <h1 className='line-4'>North</h1>
          </div>
      </div>
      <div className='about-section'>
        <h3>Designed as a high performance football acedemy the focus of Champions Football Academy North will be optimizing the the physical and mental performance of every athlete admitted to the program. We will prepare athletes for challenges on and off the field.</h3>
        <div className='highlight'>
          <h3>Our Mission is to develop well rounded highly skilled football players.</h3>
        </div>
        
      </div>
      <div className='landing-page-section-container'>
        <div className="info-section image-reversed">
          <div className="intro">
            <h2>Management & Coaching</h2>
            <p>Quality training and development requires superior coaching and leadership. Champions Football Academy North has secured a head coach and technical director with full UEFA coaching credentials and an extensive playing pedigree in Europe.</p>
            <a href="/management-and-coaching">
              <button className='read-more-btn'>Read More</button>
            </a>
          </div>
          <img className="info-image" src={TeamPhoto} alt="" />
        </div>
        <div className="info-section">
          <div className="intro">
            <h2>The Process</h2>
            <p>The “Champions Process” relies on one singularly important component – the player. To be successful in our Academy the player must be fully vested in the process. Punctual attendance should be a desire of the player and not a requirement of the Academy. There must be an eagerness to take instruction and learn from that instruction. It is to these traits and characteristcs that the “Champions Process” is geared.</p>
            <a href="/the-process">
              <button className='read-more-btn'>Read More</button>
            </a>
          </div>
          <img className="info-image" src={ThreeSoccerFields} alt="" />
        </div>
        <div className="info-section image-reversed">
          <div className="intro">
            <h2>Partnerships</h2>
            <p>In partnering with like-minded associations, it is our intention to provide our players with a wider range of opportunities to take advantage of. As an organization, we will continue to foster further links that will benefit and enhance our commitment to player development.</p>
            <a href="/partnerships">
              <button className='read-more-btn'>Discover Partnerships</button> 
            </a>
          </div>
          <img className="info-image" src={SoccerBalls} alt="" />
        </div>
        <div className="info-section">
          <div className="intro">
            <h2>Highlighted Players</h2>
            <p>If the training methods and style of play promoted by Champions Football Academy North are successful, players within the academy will be awarded the opportunity to train and tryout in Europe. An extensive array of contacts developed over the last forty years by our Technical Director affords us this priviledge.</p>
            <a href="/highlighted-players">
              <button className='read-more-btn'>Find out more</button>
            </a>
          </div>
          <img className="info-image" src={SoccerGoalie} alt="" />
        </div>
      </div>
    </>
  )
};