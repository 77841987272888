import React from 'react';
import './ProcessListItem.css'

export function ProcessListItem ({heading, item, number}, index) {
  return (
    <div key={index} className='process-list-item'>
      <div className='heading-container'>
        <h2 className='number'>{number + 1}</h2>
        <div className='text-container'>
          <h3 className='heading'>{heading}</h3>
          <p>{item}</p>
        </div>
        
      </div>
      
    </div>
  )
}