import React from 'react';
import './Contact.css';
import RegistrationForm from '../../assets/forms/registration-form.pdf';

export function Contact () {
  
  const contactInfo = {
    emailOne: "tony@championsnorth.ca",
    emailTwo: "tom@championsnorth.ca",
    address: {
      firstLine: "17035 Yonge Street",
      secondLine: "Newmarket, ON",
      thirdLine: "L3Y 5Y1"
    }
  };
  const registrationFormName = "Champions-Football-Academy-North-Registration";

  return (
    <div className='page'>
      <div className="registration-section">
        <h1 className='header'>Registration</h1>
        <div className="registration">
          <p>Champions Football Academy North will be offering both part time and full-time training for both males and females ranging in age from 13 - 22.</p>
          <p className='registration-para'><span className='bold-text'>Part time</span> registration entitles the player to <span className='bold-text'>three 2-hour</span> sessions per week.</p>
          <p><span className='bold-text'>Full time</span> registration entitles the player to <span className='bold-text'>six 2-hour</span> sessions per week.</p>
          <p className='registration-para cta-para'>To register, please fill out the registration form and email back.</p>
          <a href={RegistrationForm} download={registrationFormName}>
            <button className='register-btn'>Download Registration Form</button>
          </a>
        </div>
      </div>
      <div className="contact-us-section">
        <h1 className='header'>Contact Us</h1>
        <div className='contact-info'>
          <a href={`mailto:${contactInfo.emailOne}`}>
            <h3 className='email'>{contactInfo.emailOne}</h3>
          </a>
          <a href={`mailto:${contactInfo.emailTwo}`}>
            <h3 className='email'>{contactInfo.emailTwo}</h3>
          </a>
          <div className='address'>
            <h3>Mailing Address</h3>
            <p>{contactInfo.address.firstLine}</p>
            <p>{contactInfo.address.secondLine}</p>
            <p>{contactInfo.address.thirdLine}</p>
        </div>
      </div>
      </div>
    </div>
  )
}