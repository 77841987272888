import React, {useState, useEffect} from 'react';
import { ProcessListItem } from '../../components/ProcessListItem/ProcessListItem';
import sanityClient from '../../sanityClient';
import './OurProcess.css'

import SoccerField from '../../assets/images/soccer-field-3.jpg';

export function OurProcess () {
  const pageContent = {
    pageTitle: "Our Process",
    introParagraph: "Champions Football Academy North has been developed with the primary intention of filling a gap in North American soccer training and development. The intention is to introduce European coaching and training methods and processes to inspire a more comprehensive understanding of the game. Strength and physicality are important components of the game but equally important and perhaps more important, are touch, finesse and soccer IQ."
  }

  const processList = [
    {
      heading: 'Personal Commitment', 
      item: 'Be accepted to our program and commit fully to our methods.'
    },
    {
      heading: 'Assessment', 
      item: 'Testing and measurements. Identify individual needs. Evaluate and set goals.'
    },
    {
      heading: 'Start Training', 
      item: 'Learn and implement training methods presented.'
    },
    {
      heading: 'Competing', 
      item: 'Put your training to the test.'
    },
    {
      heading: 'Review and Evaluate Performance', 
      item: 'More planning and goal setting.'
    },
    {
      heading: 'More Competition', 
      item: 'Compete, train, compete, repeat again and again'
    },
    {
      heading: 'Evaluation', 
      item: 'Monthly evaluations culminating in end of term evaluation.'
    },
    {
      heading: 'Achieve', 
      item: 'Achieve the goals set; train in Europe as a final preparation.'
    },
    {
      heading: 'Reward',
      item: 'Reward Direct tryout with a team(s) in Europe.'
    },
    {
      heading: 'Success',
      item: 'Enjoy the moment! Now let’s get back to work.'
    },
  ];

  const pillars = [
    {
      heading: "Skills development",
      items: [
        "Importance of first touch",
        "Ball mastery", 
        "Decision making",
        "Speed of play",
      ],
    },
    {
      heading: "Soccer IQ",
      items: [
        "Understanding the principles of play",
        "Develop an understanding of roles and responsibilities in each position.",
        "Tactical development",
        "Formations (ideology and methodology)",
      ],
    },
    {
      heading: "Player development",
      items: [
        "Building player confidence",
        "Player conduct on and off field",
        "Monthly player evaluation(s)",
      ],
    },
    {
      heading: "Fitness",
      items: [
        "Developing the proper physique/body type for soccer",
        "Focus on agility in addition to speed.",
        "Monthly fitness evaluations",
      ],
    },
    {
      heading: "Nutritional Education",
      items: [
        "Proper dietary programs/regimes to maximize training efficiency"
      ],
    },
  ];

  return (
    <div className="our-process">
      <div className="our-process-header app-section">
        <h1>{pageContent.pageTitle}</h1>
        <p>{pageContent.introParagraph}</p>
      </div>
      <div className="development-pillars-section app-full-width">
        <div className="development-pillars-container app-section">
          <h2>Development Pillars</h2>
          <div className="development-pillars">
          {pillars.map( ({heading, items}) => {
            return (
              <div className="pillar">
                <h3>{heading}</h3>
                {items.map( item => {
                  return <p>{item}</p>
                })}
              </div>
            )
          })}
          </div>
        </div>
      </div>
      <div className="process-list-section app-full-width">
        
        <div className="process-list">
        <div className="process-list-header">
          <h2>The "Champions" Process</h2>
          <p>At its core, we are primarily a training academy. We will participate in friendly matches and tournaments but our primary goal is to prepare our players for the opportunity to try out with European teams. In Panagiotis Tsalouchidis, we have a reliable and defined pathway to Europe.</p>
        </div>
          {processList.map(({heading, item, key}, index) => 
            <ProcessListItem 
              heading={heading} 
              item={item}  
              number={index}
            />
          )}
        </div>
      </div>
    </div>
  )
};