import React from 'react';
import './AboutUs.css';

import Yioti1 from '../../assets/images/yioti-1.png'
import Yioti2 from '../../assets/images/yioti-2.png'
import Yioti3 from '../../assets/images/yioti-3.png'


export function AboutUs () {

  const pageContent = {
    pageTitle: "About Us"
  }

  

  // ! change to array for about paragraphs
  const profiles = [
    {
      name: "Panagiotis Tsalouchidis",
      title: "Technical Director / Head Coach",
      aboutParaOne: "Our Technical Director (TD) and Head Coach is Panagiotis Tsalouchidis. Yioti as he is more commonly known, is a former Greek footballer who played professionally from 1983 to 1999. He made five hundred league appearances in Greek football for Olympiacos, PAOK and Veria FC. Coach Tsalouchidis also represented Greece at the international level earning 76 caps between 1987 and 1995. In 1994 he represented Greece at the FIFI World Cup. Since retiring, Coach Tsalouchidis has earned his UEFA Licensing credentials.",
    },
    {
      name: "Antonio Ferazzoli",
      title: "Assistant Coach / Administration",
      aboutParaOne: "Antonio has been coaching soccer for just under two decades. Seven of which, with multiple teams per season, have been at the Ontario Provincial Development League (OPDL) level. Antonio holds a number of different coaching certificates. C License Certified, National B Part 1 and National B Part 2 (in progress) among the most prominent. As a player, Antonio distinguished himself provincially as a member of the Ontario Provincial Program and internationally as a member – for two years – of the Roma Youth Academy in Italy. Antonio looks forward to working under the leadership and guidance of Coach Tsalouchidis.",
    }
  ]

  return (
    <div className="page">
      <div className='staff-profile'>
        <h2>{profiles[0].name}</h2>
        <h3>{profiles[0].title}</h3>
        <p>{profiles[0].aboutParaOne}</p>
        {profiles[0].aboutParaTwo && <p>{profiles[0].aboutParaTwo}</p>}
        <div className='coaches-images-container'>
          <img className='coach-image' src={Yioti1} alt="" />
          <img className='coach-image' src={Yioti2} alt="" />
          <img className='coach-image' src={Yioti3} alt="" />
        </div>
      </div>
      <div className='staff-profile'>
        <h2>{profiles[1].name}</h2>
        <h3>{profiles[1].title}</h3>
        <p>{profiles[1].aboutParaOne}</p>
        {profiles[1].aboutParaTwo && <p>{profiles[0].aboutParaTwo}</p>}
      </div>
      
    </div>
  )
}