import React from 'react';
import './HighlightedPlayers.css';

import Oumar1 from '../../assets/images/players/oumar-1.png';
import Oumar2 from '../../assets/images/players/oumar-2.png';
import Oumar3 from '../../assets/images/players/oumar-3.png';
import Oumar4 from '../../assets/images/players/oumar-4.png';
import Chiemeka1 from '../../assets/images/players/chiemeka-1.png';
import Chiemeka2 from '../../assets/images/players/chiemeka-2.png';
import Chiemeka3 from '../../assets/images/players/chiemeka-3.png';
import Hlias1 from '../../assets/images/players/hlias-1.png';
import George1 from '../../assets/images/players/george-1.png';
import GreekTryout1 from '../../assets/images/players/greek-tryout-1.png';
import GreekTryout2 from '../../assets/images/players/greek-tryout-2.png';
import GreekTryout3 from '../../assets/images/players/greek-tryout-3.png';
import GreekTryout4 from '../../assets/images/players/greek-tryout-4.png';
import GreekTryout5 from '../../assets/images/players/greek-tryout-5.png';

import TeamPhoto from '../../assets/images/team-photo.jpg';

export function HighlightedPlayers () {
  return (
    <div className="page highlighted-players">
      <div className="app-section">
      <h1>Highlighted Players</h1>
      <div className="player-highlight">
        <h2>Oumar Diallo</h2>
        <p>Oumar Diallo is a 2005 player from Aurora Ontario. Thanks to Technical Director Tsalouchidis, and of course the dedication of the player and his family, efforts Oumar was signed to A.S.D Cannara in Italy in 2021. After one season he was signed by Inter Milan of Seria A into their U19 Program.Good work Oumar.</p>
        <div className="player-images-container">
          <img className="player-image" src={Oumar1} alt="" />
          <img className="player-image" src={Oumar2} alt="" />
          <img className="player-image" src={Oumar4} alt="" />
          <img className="player-image" src={Oumar3} alt="" />
        </div>
      </div>
      <div className="player-highlight">
        <h2>Chiemeka Azurunwa</h2>
        <p>Chiemeka Azurunwa is also from Aurora Ontario. He was identified by Coach Tsalouchidis at one of our ID Camps and brought to Italy in 2022 as part of an elite training/travelling team. Equipping himself well in Italy the Coach arranged tryouts with PAOK, Olympiacos and Veria in Greece. Veria FC, with whom we are officially affiliated offered Chiemeka a contract. The other two organizations, both first division teams, provided him with valuable feedback. Both teams have requested to see him again. Keep working hard Chieme!</p>
        <div className="player-images-container">
          <img className="player-image" src={Chiemeka1} alt="" />
          <img className="player-image" src={Chiemeka2} alt="" />
          <img className="player-image" src={Chiemeka3} alt="" />
        </div>
      </div>
      <div className="player-highlight">
        <h2>Hlias Hliadis</h2>
        <p>Grew up playing soccer in Toronto. Technical Director Tsalouchidis scouted him and helped him sign a contract with Panathinaikos FC. He played there for three years. He is now playing in the MLS for CF Montreal.</p>
        <div className="player-images-container">
          <img className="player-image" src={Hlias1} alt="" />
        </div>
      </div>
      <div className="player-highlight">
        <h2>George Pahos</h2>
        <p>George is a 2005 player from Greece. Discovered and developed by Technical Director Tsalouchidis, George was placed with Ionikos earlier this year. Ionikos FC is a first division team in the Greek Super League.</p>
        <div className="player-images-container">
          <img className="player-image" src={George1} alt="" />
        </div>
      </div>
      <div className="additional-players">
        <p>In 2023 an additional four players were brought to train in Greece. Ethan Wright, Adam Csiszer, Shane Perera and Ryan Silverstein spent two weeks training with both PAOK and Veria. This was an invaluable experience. They were able to witness first hand the speed and finesse of the European game. These young men, two 2005’s and two 2006's were also part of Coach Tsalouchidis’ high performance team that travelled to Italy. From their experience they received valuable feedback which the coach will build into their training regiment before sending them back. All three players want to be seen again. Remember boys this is a process. Continue to develop and respect the process.</p>
        <div className="player-images-container">
          <img className="player-image" src={GreekTryout1} alt="" />
          <img className="player-image" src={GreekTryout2} alt="" />
          <img className="player-image" src={GreekTryout3} alt="" />
          <img className="player-image" src={GreekTryout4} alt="" />
          <img className="player-image" src={GreekTryout5} alt="" />
        </div>
      </div>
      <div className="additional-players split-section">
        <div className="player-text-container">
          <p>In 2021, prior to our formal existence as an Academy, we held an ID Camp to which fifty-sixty players attended. Ten players were identified as an initial core group. As the process and training evolved we were able to attract an additional five highly skilled players. This group was taken to Italy by Coach Tsalouchidis to where matches against professional teams were arranged. The most prominent was against SS Lazio’s U21 Squad. All the members of this team walked away from this experience with a clearer understanding of where they need to be as footballers to achieve their goals. Most of this group remains intact and will be part of Champions Football Academy North.</p>
        </div>
        <div className="player-image-container">
          <img className="player-image" src={TeamPhoto} alt="" />
        </div>
      </div>
      </div>
    </div>
  )
};