import React from "react";
import './Footer.css';
import ChampionsNorthLogo from '../../assets/logos/champions-north.png';

export function Footer () {
  const contactInfo = {
    emailOne: "tony@championsnorth.ca",
    emailTwo: "tom@championsnorth.ca",
  };

  return (
    <div className="footer">
      <a href="/">
        <img 
          className="footer-logo"
          src={ChampionsNorthLogo} 
          alt="Champions North Logo" />
      </a>
      <div className="footer-contact">
        <a href={`mailto:${contactInfo.emailOne}`}>
          <p className='email'>{contactInfo.emailOne}</p>
        </a>
        <a href={`mailto:${contactInfo.emailTwo}`}>
          <p className='email'>{contactInfo.emailTwo}</p>
        </a>
      </div>
    </div>
  )
}